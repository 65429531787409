/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import { breakpointList } from "../helpers"

const SwipeableView = ({
  children,
  visibleElements = [1, 1, 2, 2, 3, 3],
  bleeding = 20,
  ...restProps
}) => {
  const wrapperRef = React.useRef(null)
  const innerRef = React.useRef(null)
  const [wrapperWidth, setWrapperWidth] = React.useState(0)
  const [innerWidth, setInnerWidth] = React.useState(0)
  const [activeIndex, setActiveIndex] = React.useState(0)
  const [currentVisibleElements, setCurrentVisibleElements] = React.useState(1)
  const disabled = wrapperWidth > innerWidth * children.length
  React.useEffect(() => {
    function handleResize() {
      setWrapperWidth(wrapperRef.current.clientWidth)
      setInnerWidth(innerRef.current.clientWidth)
      const breakpointIndex = breakpointList
        .map(breakpoint => parseInt(breakpoint.split("px")[0]))
        .filter(breakpoint => breakpoint < window.innerWidth).length
      const newCurrentVisibleElements = visibleElements[breakpointIndex]
      if (newCurrentVisibleElements !== currentVisibleElements)
        setCurrentVisibleElements(newCurrentVisibleElements)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return _ => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  React.useEffect(() => {
    if (disabled) {
      setActiveIndex(0)
    }
  }, [disabled])
  React.useEffect(() => {
    const rest = children.length % currentVisibleElements
    const last = children.length - (rest > 0 ? rest : currentVisibleElements)
    const reset = activeIndex > last
    if (reset && currentVisibleElements > 1) setActiveIndex(last)
  }, [
    activeIndex,
    children,
    visibleElements,
    currentVisibleElements,
    setActiveIndex,
  ])

  return (
    <div
      sx={{ overflow: "hidden" }}
      ref={ref => {
        wrapperRef.current = ref
        if (ref) setWrapperWidth(ref.clientWidth)
      }}
    >
      <SwipeableViews
        index={activeIndex}
        onChangeIndex={setActiveIndex}
        enableMouseEvents={true}
        disabled={disabled}
        resistance={true}
        sx={{
          userSelect: disabled ? "all" : "none",
          width: innerWidth,
          overflowX: "visible!important",
          ml: "1px",
        }}
      >
        {children.map((child, index) => (
          <div
            key={index}
            ref={ref => {
              if (index === 0) {
                innerRef.current = ref
                if (ref) setInnerWidth(ref.clientWidth)
              }
            }}
            sx={{
              width: Array.isArray(visibleElements)
                ? visibleElements.map(
                    (visibleElements, index) =>
                      wrapperWidth / visibleElements -
                      (index < 4 ? bleeding : 0)
                  )
                : wrapperWidth / visibleElements - bleeding,
              pr: ["10px", "10px", "10px", "30px"],
            }}
          >
            {child}
          </div>
        ))}
      </SwipeableViews>
      <div sx={{ mt: 2, display: ["none", "none", "none", "block"] }}>
        <button
          onClick={() =>
            setActiveIndex(activeIndex =>
              activeIndex === 0 ? 0 : activeIndex - 1
            )
          }
          sx={{
            mr: 2,
            userSelect: "none",
            backgroundColor: "backgrounds.primary",
          }}
        >
          Prev
        </button>
        <button
          sx={{ userSelect: "none", backgroundColor: "backgrounds.primary" }}
          onClick={() => setActiveIndex(activeIndex => activeIndex + 1)}
        >
          Next
        </button>
      </div>
    </div>
  )
}
export default SwipeableView
