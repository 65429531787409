/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { useIsClient } from "../hooks/use-is-client"
import React from "react"
import Link from "./link"
import querystring from "query-string"

const Blog = ({ location, ...restProps }) => {
  const data = useStaticQuery(graphql`
    query {
      posts: allContentfulBlogPost(filter: {}) {
        edges {
          node {
            category
            date
            fullStory {
              json
            }
            slug
            teaser {
              teaser
            }
            title
            tags
          }
        }
      }
    }
  `)
  const isClient = useIsClient()
  const [ignoreQuery, setIgnoreQuery] = React.useState(false)
  const query = React.useMemo(
    () => isClient && querystring.parseUrl(location.href).query,
    [isClient, location, ignoreQuery]
  )
  const posts = React.useMemo(
    () =>
      data.posts.edges
        .map(entry => entry.node)
        .sort((a, b) => new Date(a.date) - new Date(b.date)),
    [data]
  )
  const tagList = React.useMemo(() => {
    const tagMap = {}
    posts.forEach(post => post.tags.forEach(tag => (tagMap[tag] = true)))
    return Object.keys(tagMap).sort((a, b) => (a > b) - (a < b))
  }, [posts])
  const [selectedTags, setSelectedTags] = React.useState([])
  const addTag = tag => {
    setSelectedTags(selectedTags => [...selectedTags, tag])
  }
  const removeTag = tag =>
    setSelectedTags(selectedTags =>
      selectedTags.filter(selectedTag => selectedTag !== tag)
    )
  const toggleTag = tag => {
    selectedTags.includes(tag) ? removeTag(tag) : addTag(tag)
  }
  const categoryList = React.useMemo(() => {
    const categoryMap = {}
    posts.forEach(post => (categoryMap[post.category] = true))
    return Object.keys(categoryMap).sort((a, b) => (a > b) - (a < b))
  }, [posts])
  const [selectedCategory, setSelectedCategory] = React.useState(null)

  let filteredPosts = React.useMemo(() => {
    let filteredPosts = posts
    if (selectedTags.length > 0)
      filteredPosts = posts.filter(post =>
        post.tags.some(tag => selectedTags.includes(tag))
      )
    if (selectedCategory)
      filteredPosts = filteredPosts.filter(
        post => post.category === selectedCategory
      )
    return filteredPosts
  }, [posts, selectedTags, selectedCategory])

  React.useEffect(() => {
    if (isClient && !ignoreQuery) {
      if (query.tags) setSelectedTags(JSON.parse(query.tags))
      if (query.category) setSelectedCategory(query.category)
    }
  }, [isClient, query])
  React.useEffect(() => {
    if (ignoreQuery || (query === {} && !ignoreQuery)) {
      const qs = querystring.stringify({
        ...(selectedCategory && { category: selectedCategory }),
        ...(selectedTags &&
          selectedTags.length > 0 && { tags: JSON.stringify(selectedTags) }),
      })
      setIgnoreQuery(true)
      window.history.pushState(
        "page2",
        document.title,
        `${window.location.pathname}${qs.length > 0 ? "?" + qs : ""}`
      )
    } else if (query) setIgnoreQuery(true)
  }, [selectedCategory, selectedTags, query, setIgnoreQuery])

  return (
    <div>
      <div sx={{ mb: 2 }}>
        <select
          onChange={event =>
            setSelectedCategory(
              event.target.value === "0" ? null : event.target.value
            )
          }
          value={selectedCategory || "0"}
        >
          Filter by Category
          <option value="0">None</option>
          {categoryList.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
      <div>
        {tagList.map((tag, index) => (
          <button
            key={index}
            sx={(() => {
              const selected = selectedTags.includes(tag)
              return {
                background: selected ? "#ccc" : "#ddd",
                mr: 2,
                px: 10,
                py: "5px",
                cursor: "pointer",
                border: `1px solid ${selected ? "#888" : "transparent"}`,
              }
            })()}
            onClick={() => toggleTag(tag)}
          >
            {tag}
          </button>
        ))}
      </div>
      <div>
        {filteredPosts.map((post, index) => (
          <Link
            key={index}
            sx={{ display: "block", mt: 2 }}
            link={`/blog/${post.slug}`}
          >
            {post.title}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Blog
