/** @jsx jsx */
import { jsx } from "theme-ui"
import PropTypes from "prop-types"
import HeroPattern from "./hero-pattern"
import AccordionPattern from "./accordion-pattern"
import TabsPattern from "./tabs-pattern"
import CarouselPattern from "./carousel-pattern"
import BlogPattern from "./blog-pattern"
import BasicPattern from "./basic-pattern"
import SwipeableViewPattern from "./swipeable-view-pattern"

const Blade = ({ blade, ...restProps }) => {
  const patterns = {
    Hero: <HeroPattern blade={blade} {...restProps} />,
    Accordion: <AccordionPattern blade={blade} {...restProps} />,
    Carousel: <CarouselPattern blade={blade} {...restProps} />,
    SwipeableView: <SwipeableViewPattern blade={blade} {...restProps} />,
    Tabs: <TabsPattern blade={blade} {...restProps} />,
    Blog: <BlogPattern blade={blade} {...restProps} />,
    Basic: <BasicPattern blade={blade} {...restProps} />,
  }
  return patterns[blade.patternType]
}

Blade.propTypes = {
  blade: PropTypes.object,
}
export default Blade
