/** @jsx jsx */
import { jsx } from "theme-ui"
import { getBackgroundList } from "../helpers"

const Hero = ({ desktopImage, mobileImage, children, ...restProps }) => {
  return (
    <div
      sx={{
        height: "500px",
        width: "100%",
        background: getBackgroundList({ desktopImage, mobileImage }),
        backgroundSize: "cover",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        color: desktopImage || mobileImage ? "white" : "text",
      }}
      {...restProps}
    >
      <div
        sx={{
          maxWidth: "1340px",
          px: theme => theme.spacing.horizontal,
          width: "100%",
          mx: "auto",
        }}
      >
        {children}
      </div>
    </div>
  )
}

export default Hero
