import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import SEO from "../../src/components/seo"

const Page = ({ children, data }) => (
  <>
    <SEO title={data.title} description={data.description.description} />
    {children}
  </>
)

Page.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.shape({
    description: PropTypes.shape({
      description: PropTypes.string.isRequired,
    }).isRequired,
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export default Page

export const query = graphql`
  fragment PageData on ContentfulPage {
    description {
      description
    }
    id
    slug
    title
  }

  fragment ContentCardData on ContentfulContentCard {
    title
    patternType
    body {
      json
    }
    url
    desktopImage {
      title
      file {
        url
        fileName
      }
    }
    mobileImage {
      title
      file {
        url
        fileName
      }
    }
    video {
      file {
        url
      }
    }
    classes
  }

  fragment Blade on ContentfulPage {
    blades {
      title
      body {
        json
      }
      patternType
      url
      desktopImage {
        title
        file {
          url
          fileName
        }
      }
      mobileImage {
        title
        file {
          url
          fileName
        }
      }
      classes
      contentCards {
        ...ContentCardData
        contentCards {
          ...ContentCardData
          contentCards {
            ...ContentCardData
            contentCards {
              ...ContentCardData
              contentCards {
                ...ContentCardData
                contentCards {
                  ...ContentCardData
                  contentCards {
                    ...ContentCardData
                    contentCards {
                      ...ContentCardData
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
